import { FormControlLabel, Grid, TextField, Typography, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CheckBox, CheckBoxOutlineBlank, MailOutline, WhatsApp, Instagram } from '@material-ui/icons'
import { useState } from "react";
import { useForm, ValidationError } from '@formspree/react';
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '90vh',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(16),
        [theme.breakpoints.down('sm')]: {
            minHeight: '100vh',
            paddingTop: theme.spacing(5),
        },
    },
    container: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {

    },
    title: {
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 38,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            fontSize: 32,
        },
    },
    subtitle: {
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 20,
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            marginBottom: 0,
        },
    },
    inputsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            marginBottom: 0,
        },
    },
    textareaContainer: {
        width: '90%',
    },
    input: {
        background: theme.colors.input.background,
        borderColor: theme.colors.input.border,
        borderRadius: 10,
        height: 54,
        width: '48%',
        marginBottom: 5,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: theme.colors.input.border,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.colors.input.border,
            },
        },
    },
    textarea: {
        background: theme.colors.input.background,
        borderColor: theme.colors.input.border,
        borderRadius: 2,
        width: '100%',
        marginBottom: theme.spacing(1),
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: theme.colors.input.border,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.colors.input.border,
            },
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '90%',
    },
    button: {
        background: theme.colors.primary,
        border: 'none',
        padding: 0,
        width: 140,
        height: 40,
        borderRadius: 10,
        color: theme.colors.textLight,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 20,
        cursor: 'pointer'
    },
    buttonDisabled: {
        background: theme.colors.textGrey,
        border: 'none',
        padding: 0,
        width: 140,
        height: 40,
        borderRadius: 10,
        color: theme.colors.textLight,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 20,
    },
    successMessageContainer: {
        width: '100%'
    },
    successMessage: {
        textAlign: 'center',
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
    },
    radiosContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    bodyContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(5)
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        width: '50%',
        borderRadius: 10,
        boxShadow: '0px 0px 16px 1px #ccc',
        [theme.breakpoints.down('md')]: {
            width: '95%',
        },
    },
    image: {
        width: '40%'
    },
    checkbox: {
        width: '90%',
        color: theme.colors.textDark
    },
    socialMediaContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginBottom: theme.spacing(5)
    },
    socialMediaBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '45%',
        [theme.breakpoints.down('lg')]: {
            width: '95%',
            flexDirection: 'column',
        },
    },
    socialMediaBoxItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
            width: '100%',
        },
    },
    socialMediaBoxIcon: {
        marginBottom: theme.spacing(1),
        color: theme.colors.primary
    },
    socialMediaBoxText: {
        color: theme.colors.textDark,
        fontSize: 18,
        fontFamily: theme.fonts.fontFamily
    },
    link: {
        color: 'blue',
        cursor: 'pointer'
    }
}));

const Contact = () => {

    const classes = useStyles();

    const [checkbox, setCheckbox] = useState(false);
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [stateFornecedor, handleSubmitFornecedor] = useForm("xyylrypj");
    const [stateCliente, handleSubmitCliente] = useForm("xleaqelb");

    const history = useHistory();

    function navigateToFaq() {
        history.push('/faq')
    }

    function ValidateEmail() {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    return (
        <Grid container className={classes.root}>
            <Grid container className={classes.container}>
                <Grid item className={classes.header}>
                    <Typography variant="h3" align="center" className={classes.title}>
                        Fale conosco
                    </Typography>
                    <Typography variant="body1" align="center" className={classes.subtitle}>
                        Deseja falar com a gente? Entre em contato <br /> por meio de um dos métodos abaixo.<br />
                        Você pode verificar se sua dúvida já existe <a onClick={navigateToFaq} className={classes.link}>aqui</a>.
                    </Typography>
                </Grid>
                <Grid container className={classes.bodyContainer}>
                    <Grid item className={classes.body}>
                        <Grid container className={classes.socialMediaContainer}>
                            <Grid container className={classes.socialMediaBox}>
                                <Grid item className={classes.socialMediaBoxItem}>
                                    <MailOutline className={classes.socialMediaBoxIcon} />
                                    <Typography className={classes.socialMediaBoxText}>contato@construrapi.com</Typography>
                                </Grid>
                                <Grid item className={classes.socialMediaBoxItem}>
                                    <WhatsApp className={classes.socialMediaBoxIcon} />
                                    <Typography className={classes.socialMediaBoxText}>+55 86 8114-6400</Typography>
                                </Grid>
                                <Grid item className={classes.socialMediaBoxItem}>
                                    <Instagram className={classes.socialMediaBoxIcon} />
                                    <Typography className={classes.socialMediaBoxText}>@constru.rapi</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <form onSubmit={checkbox ? handleSubmitFornecedor : handleSubmitCliente} className={classes.box}>
                            <Grid item className={classes.inputsContainer}>
                                <div className={classes.input} >
                                    <TextField
                                        style={{
                                            width: '100%'
                                        }}
                                        InputLabelProps={{ shrink: false }}
                                        label=""
                                        placeholder="Seu nome"
                                        variant="outlined"
                                        value={nome}
                                        onChange={(e) => setNome(e.target.value)}
                                        name="name"
                                    />
                                </div>
                                <div className={classes.input} >
                                    <TextField
                                        style={{
                                            width: '100%'
                                        }}
                                        InputLabelProps={{ shrink: false }}
                                        label=""
                                        placeholder="Seu email"
                                        variant="outlined"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        name="email"
                                    />
                                </div>
                            </Grid>
                            <Grid item className={classes.textareaContainer}>
                                <TextField
                                    multiline
                                    rows={10}
                                    className={classes.textarea}
                                    InputLabelProps={{ shrink: false }}
                                    type="textarea"
                                    label=""
                                    placeholder="Sua mensagem"
                                    variant="outlined"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    name="message"
                                />
                                <ValidationError field="email" prefix="Email" errors={stateCliente.errors} />
                            </Grid>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                                        checkedIcon={<CheckBox fontSize="small" />}
                                        checked={checkbox}
                                        onChange={(e) => setCheckbox(e.target.checked)}
                                        style={{
                                            color: "#f18750",
                                        }}
                                    />
                                }
                                label="Sou fornecedor"
                                className={classes.checkbox}
                            />

                            <input
                                type="hidden"
                                name="_subject"
                                id="email-subject"
                                value={checkbox ? "Nova mensagem de um fornecedor" : "Nova pergunta de um cliente"}
                            />
                            <Grid item className={classes.buttonContainer}>
                                {
                                    stateCliente.succeeded || stateFornecedor.succeeded ?
                                        <div className={classes.successMessageContainer}>
                                            <Typography className={classes.successMessage}>
                                                Agradecemos pela sua mensagam! <br />
                                                Responderemos assim que possível.
                                            </Typography>
                                        </div>
                                        :
                                        <button
                                            type="submit"
                                            disabled={stateFornecedor.submitting || stateCliente.submitting || nome.length == 0 || message.length == 0 || !ValidateEmail() || stateCliente.succeeded || stateFornecedor.succeeded}
                                            className={stateFornecedor.submitting || stateCliente.submitting || nome.length == 0 || message.length == 0 || !ValidateEmail() ? classes.buttonDisabled : classes.button}
                                        >
                                            {
                                                stateFornecedor.submitting || stateCliente.submitting ? 'Enviando...' :
                                                    stateCliente.succeeded || stateFornecedor.succeeded ? 'Mensagem enviada!' : 'Enviar'
                                            }
                                        </button>
                                }
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Contact;