import Navbar from 'react-bootstrap/Navbar';
import { Container, Nav } from 'react-bootstrap';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-scroll';
import { MenuOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({

  item: {
    cursor: 'pointer',
    color: theme.colors.textLight,
    '&:hover': {
      color: theme.colors.textLight,
    },
    fontFamily: theme.fonts.fontFamily
  },

  contactButton: {
    backgroundColor: theme.colors.textLight,
    borderColor: 'transparent',
    padding: '8px 28px',
    borderRadius: 50,
    cursor: 'pointer',
  },
  contactText: {
    color: theme.colors.primary,
    fontWeight: theme.typography.fontWeightMedium
  },
  menuIcon: {
    color: theme.colors.textLight,
    fontSize: 35
  }
}));

const MainNavbar = () => {
  const classes = useStyles();

  return (
    <Container className="navbar">
      <Navbar style={{ width: '100%', marginTop: 10}} expand="lg">
        <Navbar.Brand>
          <img
            alt=""
            src='/static/images/logo_gray.png'
            width="150"
            className="d-inline-block align-top w-"
          />
        </Navbar.Brand>
        <Navbar.Toggle>
          <MenuOutlined className={classes.menuIcon} />
        </Navbar.Toggle>
        <Navbar.Collapse style={{ width: '100%' }} id="basic-navbar-nav">
          <Nav style={{ width: '100%' }} className="mr-auto justify-content-end align-items-center">
            <Nav.Link>
              <Link to="home" spy={true} smooth={true} duration={500}>
                <Typography className={classes.item} align='center' variant='h6' >Início</Typography>
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link className={classes.item} to="about" spy={true} smooth={true} duration={500}>
                <Typography align='center' variant='h6' >Sobre</Typography>
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link className={classes.item} to="products" spy={true} smooth={true} duration={500}>
                <Typography align='center' variant='h6' >Produtos</Typography>
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link className={classes.item} to="contact" spy={true} smooth={true} duration={500}>
                <button className={classes.contactButton}>
                  <Typography align='center' variant='h6' className={classes.contactText} >Contato</Typography>
                </button>
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}

export default MainNavbar;