import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { APP_STORE, PLAY_STORE } from "../../consts/store";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(12),
        },
    },
    firstSection: {
        minHeight: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        },
    },
    header: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
        },
    },
    headerTitle: {
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
        marginBottom: theme.spacing(1),
        fontSize: 48,
        [theme.breakpoints.down('sm')]: {
            fontSize: 36,
        },
    },
    body: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '75%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '28%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        },
    },
    cardHeader: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
    },
    cardHeaderTitle: {
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 20
    },
    cardBodyText: {
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
    },
    secondSection: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(8),
            justifyContent: 'center',
            flexDirection: 'column',
        }
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '50vh',
        width: '40%',
        [theme.breakpoints.down('md')]: {
            width: '85%',
        },
    },
    imagesContainer: {
        width: '55%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '85%',
        },
    },
    title: {
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 44,
        marginBottom: theme.spacing(2),
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            fontSize: 34,
        },
    },
    textBody: {
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 18,
        marginBottom: theme.spacing(4),
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            fontSize: 16,
        },
    },
    badgesTitle: {
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 18,
        marginBottom: theme.spacing(1)
    },
    badgeTitleContainer: {
        width: '100%'
    },
    badgeContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    badge: {
        marginRight: 20
    },
    phone: {
        [theme.breakpoints.down('sm')]: {
            width: '50%'
        },
    },
    line: {
        width: 90,
        height: 2,
        background: theme.colors.primary
    }
}));

const About = () => {

    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid container className={classes.firstSection}>
                <Grid item className={classes.header}>
                    <Typography variant="h3" align="center" className={classes.headerTitle}>
                        Sobre o app
                    </Typography>
                    <div className={classes.line}/>
                </Grid>
                <Grid container className={classes.body}>
                    <Grid item className={classes.card}>
                        <div className={classes.cardHeader}>
                            <img alt="" src='/static/images/about-icon-1.png' className={classes.icon} />
                            <Typography variant="h6" align="center" className={classes.cardHeaderTitle}>
                                Materiais de construçao para sua obra
                            </Typography>
                        </div>
                        <Typography variant="body1" align="center" className={classes.cardBodyText}>
                            Em parceria com diferentes fornecedores, o app oferece os melhores produtos para a sua obra.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.card}>
                        <div className={classes.cardHeader}>
                            <img alt="" src='/static/images/about-icon-2.png' className={classes.icon} />
                            <Typography variant="h6" align="center" className={classes.cardHeaderTitle}>
                                Facilidade de busca e amplo catálogo de produtos
                            </Typography>
                        </div>
                        <Typography variant="body1" align="center" className={classes.cardBodyText}>
                            O construrapi dispõe de um amplo catálogo de produtos de diversas categorias. Encontre o que você precisa com apenas alguns cliques.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.card}>
                        <div className={classes.cardHeader}>
                            <img alt="" src='/static/images/about-icon-3.png' className={classes.icon} />
                            <Typography variant="h6" align="center" className={classes.cardHeaderTitle}>
                                Compras seguranças direto pelo app
                            </Typography>
                        </div>
                        <Typography variant="body1" align="center" className={classes.cardBodyText}>
                            No construrapi, você pode efetuar o pagamento de seus pedidos através de seus cartões de crédito ou pelo pix.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.secondSection} md={9}>
                <Grid item className={classes.contentContainer}>
                    <Typography variant="h3" className={classes.title}>
                        Faça compras para sua obra pelo seu celular
                    </Typography>
                    <Typography variant="body1" className={classes.textBody}>
                        Faça o download do app para efetuar suas compras. Tenha acesso a um vasto catálogo de produtos de construção civil e acompanhe a entrega de seus pedidos direto pelo App!
                    </Typography>
                    <div className={classes.badgeTitleContainer}>
                        <Typography variant="h6" align="left" className={classes.badgesTitle}>
                            Obtenha o App
                        </Typography>
                        <div className={classes.badgeContainer}>
                            <a target="_blank" rel="noreferrer" href={APP_STORE}>
                                <img alt="" src='/static/images/app-store.png' className={classes.badge} />
                            </a>
                            <a target="_blank" rel="noreferrer" href={PLAY_STORE}>
                                <img alt="" src='/static/images/google-play.png' className={classes.badge} />
                            </a>
                        </div>
                    </div>
                </Grid>
                <Grid item className={classes.imagesContainer}>
                    <img alt="" src='/static/images/about-phone-1.png' className={classes.phone} />
                    <img alt="" src='/static/images/about-phone-2.png' className={classes.phone} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default About;