import { Element } from 'react-scroll';
import HomeView from '../Home';
import AboutView from '../About';
import Navbar from '../../components/Navbar';
import MarkView from '../Mark';
import CategoryView from '../Category';
import ContactView from '../Contact';
import Footer from '../Footer';
import { WhatsApp } from '@material-ui/icons';
import { useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { WHATSAPP_LINK } from '../../consts/store';
import CookiesModal from '../../components/CookiesModal';

const Main = () => {

    const matches = useMediaQuery('(min-width:700px)');

    const [wpp, setWpp] = useState('');

    async function getWhatsappNumber() {
        axios.post('/graphql', {query: `
        {
            getContact{
                telefone
            }
        }
        `}, { headers: { "ignore-ctx": "true" } })
        .then((res: any) => {
            setWpp(res?.data?.data?.getContact?.telefone);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getWhatsappNumber();
    }, [])

    return (
        <>
            <Navbar />
            <CookiesModal />
            <Element name="home">
                <HomeView />
            </Element>
            <Element name="about">
                <AboutView />
            </Element>
            <Element name="products">
                {matches && <CategoryView />}
                <MarkView />
            </Element>
            <Element name="contact">
                <ContactView />
            </Element>
            <Footer />

            <a href={wpp ? WHATSAPP_LINK(wpp) : ''} className="float" target="_blank" rel="noreferrer">
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <WhatsApp style={{
                        color: '#fff',
                        fontSize: 40
                    }} />
                </div>
            </a>
        </>
    )
}

export default Main;