import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        background: theme.colors.textLight
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    statusCode: {
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 40,
        fontWeight: theme.typography.fontWeightRegular
    },
    verticalLine: {
        height: 60,
        background: theme.colors.textDark,
        width: 1.5,
        margin: 10
    },
    text: {
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 30,
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const NotFound = () => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Box className={classes.box}>
                <Typography className={classes.statusCode}>
                    404
                </Typography>
                <div className={classes.verticalLine}/>
                <Typography className={classes.text}>
                    Página não encontrada
                </Typography>
            </Box>
        </div>
    )
}

export default NotFound;