import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        bottom: 0,
        width: '90%',
        padding: theme.spacing(5),
        height: '18vh',
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            height: '42vh',
            width: '100%',
            padding: theme.spacing(2),
        },
        [theme.breakpoints.between('sm', 'md')]: {
            height: '28vh',
            width: '100%',
            padding: theme.spacing(5),
        },
    },
    box: {
        background: '#fff',
        padding: theme.spacing(3),
        borderRadius: 10,
        boxShadow: '0px 0px 16px 1px #ccc',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            flexDirection: 'column',
        },
    },
    text: {
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 20,
        width: '85%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            flexDirection: 'column',
        },
    },
    button: {
        backgroundColor: '#fff',
        border: '1.5px solid #f18750',
        padding: '8px 28px',
        borderRadius: 8,
        cursor: 'pointer',
        height: 50,
        width: '15%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: theme.spacing(2)
        },
        [theme.breakpoints.between('sm', 'md')]: {
            flexDirection: 'column',
        },
    }, 
    textButton: {
        color: theme.colors.primary,
        fontFamily: theme.fonts.fontFamily,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 20,    
    }, 
    link: {
        color: 'blue'
    }
}));

const CookiesModal = () => {

    const [open, setOpen] = useState(false);

    const classes = useStyles();

    function handleClose() {
        setCookieInfo();
        setOpen(false);
    }

    function getCookieInfo() {
        const isEnabled = localStorage.getItem('@coockie/enabled');
        setOpen(!Boolean(isEnabled));
    }

    function setCookieInfo() {
        localStorage.setItem('@coockie/enabled', "true");
    }    

    useEffect(() => {
        getCookieInfo();
    }, [])

    return (
        <>
            {
                open ?
                    <div
                        className={classes.modal}
                    >
                        <Box className={classes.box}>
                            <Typography variant='h6' className={classes.text}>
                                <b>Construrapi e os cookies:</b> a gente usa cookies para melhorar a sua experiência no site. Ao continuar navegando, você concorda com a nossa <a target="_blank" className={classes.link} href="https://app.construrapi.store/politica">Política de privacidade</a>.
                            </Typography>
                            <button className={classes.button} onClick={handleClose}>
                                <Typography className={classes.textButton}>
                                    continuar e fechar
                                </Typography>
                            </button>
                        </Box>
                    </div>
                    : <></>
            }
        </>

    )
}

export default CookiesModal;