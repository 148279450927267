import { Container, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { AndroidOutlined, Apple } from '@material-ui/icons';
import { APP_STORE, PLAY_STORE } from '../../consts/store';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            height: '85vh',
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85vh',
    },
    backgroundImage: {
        backgroundImage: 'linear-gradient(to right, rgba(232,59,60, 0.8) , rgba(241,135,80, 1))',
        overflow: 'hidden',
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: -1,
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
        },
    },
    infosContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        [theme.breakpoints.down('md')]: {
            alignItems: 'center',
        },
    },
    title: {
        color: theme.colors.textLight,
        fontFamily: theme.fonts.fontFamily,
        fontWeight: 700,
        fontSize: 50,
        [theme.breakpoints.down('sm')]: {
            fontSize: 36,
            textAlign: 'center'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: 46,
            textAlign: 'center'
        }
    },
    imagesLinksContainer: {
        width: '40%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            width: '65%',
            marginTop: theme.spacing(2),
        }
    },
    imagesLinks: {
        width: '48%',
    },
    phoneImage: {
        marginTop: theme.spacing(20),
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        }
    },
    button: {
        background: theme.colors.primary,
        border: 'none',
        padding: 0,
        width: 240,
        height: 50,
        borderRadius: 30,
        color: theme.colors.textLight,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 24,
        cursor: 'pointer'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(10),
    },
    badgeContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '40%',
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '60%',
        }
    },
    buttonStoreApple: {
        background: theme.colors.textLight,
        border: 'none',
        padding: '10px 25px',
        width: '48%',
        borderRadius: 8,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 18px',
        }
    },
    buttonStoreGoogle: {
        background: 'transparent',
        border: '1px solid #fff',
        padding: '10px 20px',
        borderRadius: 8,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '48%',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 15px',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            padding: '10px 15px',
        }
    },
    iconAndroid: {
        color: theme.colors.textLight,
        fontSize: 20,
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: 28,
        }
    },
    iconApple: {
        color: theme.colors.primary,
        fontSize: 25,
        marginBottom: theme.spacing(0.1),
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: 32,
        }
    },
    textApple: {
        color: theme.colors.primary,
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: 20,
        }
    },
    textAndroid: {
        color: theme.colors.textLight,
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: 20,
        }
    },
    imgContainer: {
        width: '30%'
    },
    img: {
        width: '80%'
    }
}));

const Home = () => {

    const classes = useStyles();

    const matches = useMediaQuery('(min-width:1200px)');

    return (
        <>
            <div className={classes.root}>
                <div className={classes.backgroundImage}/>
                <Container className={classes.container}>
                    <Grid container className={classes.infosContainer}>
                        <Grid item>
                            <Typography className={classes.title} align="left">
                                Tudo que sua obra precisa
                            </Typography>
                            <Typography className={classes.title} align="left">
                                Na palma da sua mão
                            </Typography>
                        </Grid>
                        <Grid item className={classes.badgeContainer}>
                            <a target="_blank" rel="noreferrer" href={APP_STORE} className={classes.buttonStoreApple}>
                                <Apple className={classes.iconApple}/>
                                <Typography align="left" className={classes.textApple}>
                                    Apple Store
                                </Typography>
                            </a>
                            <a target="_blank" rel="noreferrer" href={PLAY_STORE} className={classes.buttonStoreGoogle}>
                                <AndroidOutlined className={classes.iconAndroid}/>
                                <Typography align="left" className={classes.textAndroid}>
                                    Google Play
                                </Typography>
                            </a>
                        </Grid>
                    </Grid>
                    {
                        matches &&
                        <Grid container className={classes.imgContainer}>
                        <img alt="" src='/static/images/iphone-home.png' className={classes.img}/>
                    </Grid>
                    }
                    
                </Container>
            </div>
        </>
    )
}

export default Home;