import { Theme } from "@material-ui/core/styles/createTheme";
import { createTheme } from "@material-ui/core/styles";

declare module "@material-ui/styles" {
  interface DefaultTheme extends CustomTheme {}
}

declare module "@material-ui/core/styles/createTheme" {
  interface ThemeOptions extends CustomTheme {}
}

export interface CustomTheme extends Theme {
  colors: {
    primary: string
    secondary: string
    textLight: string
    textDark: string
    textGrey: string
    success: string
    input: {
      background: string
      border: string
    }
  }
  fonts: {
    fontFamily: string
  }
}

const theme = createTheme({
  colors: {
    primary: '#F18750',
    secondary: '#1F1F1F',
    textLight: '#F7F7F7',
    textDark: '#252B42',
    textGrey: '#939393',
    success: '#2aa25d',
    input: {
      background: '#F5F5F5',
      border:' #E8E8E8'
    }
  },
  fonts: {
    fontFamily: 'Inter, sans-serif'
  }
});

export { theme };
