import { Typography, ImageList, ImageListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '45vh',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            minHeight: '80vh',
            marginBottom: theme.spacing(5),
        },
        marginBottom: theme.spacing(20),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    titleContainer: {
        marginBottom: theme.spacing(10)
    },
    title: {
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
        fontWeight: 400,
        fontSize: 40,
        [theme.breakpoints.down('sm')]: {
            fontSize: 36,
        }
    },
    list: {
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        }
    }
}));

interface Mark {
    uuid: string;
    nome: string;
    url: string;
}

const MarkView = () => {

    const classes = useStyles();

    const [marks, setMarks] = useState<Mark[]>([]);

    function loadMarks() {
        axios.post('/graphql', {
            query: `
        {
            readMark{
              uuid
              nome
              url
            }
          }
        `}, { headers: { "ignore-ctx": "true" } })
            .then((res: any) => {
                setMarks(res?.data?.data?.readMark);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        loadMarks();
    }, [])

    return (
        <>
            {
                marks &&
                <div className={classes.root}>
                    <div className={classes.titleContainer}>
                        <Typography className={classes.title} align="center">
                            Conheça nossos parceiros
                        </Typography>
                    </div>
                    {

                        marks.length > 0 &&
                        <ImageList className={classes.list}>
                            {
                                marks.map((mark: Mark) => (
                                    <ImageListItem style={{
                                        margin: 8,
                                        width: 250,
                                        height: 150
                                    }} key={mark.uuid}>
                                        <img src={mark.url} title={mark.nome} />
                                    </ImageListItem>
                                ))
                            }
                        </ImageList>
                    }
                </div>
            }
        </>
    )
}

export default MarkView;