import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import NotFound from './views/NotFound';
import Main from './views/Main';
import FAQ from './views/FAQ'

const Routes: React.FC = (): JSX.Element => {

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Main} />
                <Route path="/faq" component={FAQ} />
                <Route path="/404" component={NotFound} />
                <Route path="*" component={() => <Redirect to="/404" />} />
            </Switch>
        </BrowserRouter>
    )

}

export default Routes;