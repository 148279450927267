import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import Carousel from 'react-material-ui-carousel';


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '45vh',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            marginBottom: 0
        },
        marginBottom: theme.spacing(10),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    titleContainer: {

    },
    title: {
        color: theme.colors.textDark,
        fontFamily: theme.fonts.fontFamily,
        fontWeight: 400,
        fontSize: 40,
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            fontSize: 36,
        }
    },
    list: {
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        }
    },
    carousel: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
    },
    img: {
        width: '100%',
        borderRadius: 10,
    }
}));

interface Category {
    uuid: string;
    nome: string;
    url: string;
}

const Category = () => {


    const classes = useStyles();

    const [category, setCategory] = useState<Category[]>([]);

    function loadCategory() {
        axios.post('/graphql', {
            query: `
            {
                readCategorySite{
                  uuid
                  nome
                  url
                }
              }
        `}, { headers: { "ignore-ctx": "true" } })
            .then((res: any) => {
                setCategory(res?.data?.data?.readCategorySite);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        loadCategory();
    }, [])

    return (
        <div className={classes.root}>
           <Carousel indicators={false} className={classes.carousel}>
               {
                   category.map((item: Category) => <img key={item.uuid} src={item.url} className={classes.img}/>)
               }
           </Carousel>
        </div>
    )
}

export default Category;