import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { MailOutline, WhatsApp, Instagram } from '@material-ui/icons';
import { useHistory } from "react-router";
import { APP_STORE, PLAY_STORE } from "../../consts/store";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.colors.secondary,
        width: '100%',
        minHeight: '45vh',
        [theme.breakpoints.up('md')]: {
            height: '40vh',
        },
        [theme.breakpoints.down('sm')]: {
            height: '120vh',
        }
    },
    body: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            height: '90%',
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            alignItems: 'center'
        },
        [theme.breakpoints.up('lg')]: {
            width: '80%',
            flexDirection: 'row',
            alignItems: 'flex-start',
            paddingTop: theme.spacing(5),
        }
    },
    copy: {
        color: theme.colors.textGrey,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 16,
    },
    footer: {
        minHeight: '12vh',
        width: '100%',
        background: '#191919',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        left: 0,
        [theme.breakpoints.down('lg')]: {
            minHeight: '20vh',
        },
    },
    sectionTitle: {
        color: theme.colors.textLight,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 20,
        marginBottom: theme.spacing(3)
    },
    link: {
        color: theme.colors.textGrey,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 20,
        marginBottom: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            color: theme.colors.textGrey,
        }
    },
    box: {
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: '85%',
            alignItems: 'center',
        },
    },
    boxSm: {
        width: '20%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: '85%',
            alignItems: 'center',
        },
    },
    infoText: {
        color: theme.colors.textGrey,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 18,
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        },
    },
    badgesContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    badge: {
        marginBottom: theme.spacing(2)
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.colors.textGrey
    },
    contactText: {
        color: theme.colors.textGrey,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 18,
    }
}));

const Footer = () => {

    const classes = useStyles();

    const history = useHistory();

    function navigateToFaq(){
        history.push('/faq')
    }

    return (
        <>
        <Grid container className={classes.root}>
            <Grid container className={classes.body}>
                <Grid item md={3} className={classes.box}>
                    <Typography variant="h5" className={classes.sectionTitle}>Construrapi</Typography>
                    <Typography variant="body1" className={classes.infoText}>
                    Tudo que sua obra precisa<br />
                    Na palma da sua mão.
                    </Typography>
                </Grid>
                <Grid item md={3} className={classes.boxSm}>
                    <Typography variant="h5"  className={classes.sectionTitle}>Informações</Typography>
                    <a onClick={navigateToFaq} className={classes.link}>Dúvidas frequentes</a>
                    <a target="_blank" rel="noreferrer" className={classes.link} href="https://app.construrapi.store/politica">Política de privacidade</a>
                    <a target="_blank" rel="noreferrer" className={classes.link} href="https://app.construrapi.store/procedimento-de-troca">Procedimento de troca</a>
                    <a target="_blank" rel="noreferrer" className={classes.link} href="https://app.construrapi.store/termos">Termos de Uso</a>
                </Grid>
                <Grid item md={3} className={classes.boxSm}>
                    <Typography variant="h5" className={classes.sectionTitle}>Contato</Typography>
                    <div className={classes.contactContainer}>
                        <MailOutline className={classes.icon} />
                        <Typography variant="h5" className={classes.contactText}>contato@construrapi.com</Typography>
                    </div>
                    <div className={classes.contactContainer}>
                        <WhatsApp className={classes.icon} />
                        <Typography variant="h5" className={classes.contactText}>(86) 8114-6400</Typography>
                    </div>
                    <div className={classes.contactContainer}>
                        <Instagram className={classes.icon} />
                        <Typography variant="h5" className={classes.contactText}>constru.rapi</Typography>
                    </div>
                </Grid>
                <Grid item md={3} className={classes.boxSm}>
                    <Typography variant="h5" className={classes.sectionTitle}>Obtenha o app</Typography>
                    <div className={classes.badgesContainer}>
                            <a target="_blank" rel="noreferrer" href={APP_STORE}>
                                <img alt="" src='/static/images/app-store.png' className={classes.badge} />
                            </a>
                            <a target="_blank" rel="noreferrer" href={PLAY_STORE}>
                                <img alt="" src='/static/images/google-play.png' className={classes.badge} />
                            </a>
                    </div>
                </Grid>
            </Grid>
        </Grid>
        <Grid item className={classes.footer}>
                <Typography className={classes.copy} variant="body1" align="center">
                    M & W MATERIAIS DE CONSTRUCOES LTDA - CNPJ: 42.782.370/0001-78 <br />
                    Endereço: Rua Agostinho Alves, 1905 - Fátima, Teresina/PI - CEP 64.049-478 <br />
                    &copy; Construrapi {(new Date()).toDateString().split(' ')[3]} - Todos os direitos reservados.
                </Typography>
            </Grid>
        </>
    )
}

export default Footer;