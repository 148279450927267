import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import api from '../../services/api';
import { useEffect, useState } from 'react';
import {ArrowBackIosOutlined} from '@material-ui/icons'
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerBackContainer: {
        width: '70%',
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: theme.fonts.fontFamily,
    },
    answer: {
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: theme.fonts.fontFamily,
    },
    header: {
        height: '45vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundImage: 'linear-gradient(to right, rgba(232,59,60, 0.8) , rgba(241,135,80, 1))'
    },
    title: {
        color: theme.colors.textLight,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 50,
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            fontSize: 32,
        },
    },
    subtitle: {
        color: theme.colors.textLight,
        fontFamily: theme.fonts.fontFamily,
        fontSize: 30,
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            marginBottom: 0,
        },
    },
    box: {
        background: '#f7f7f7',
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '45vh'
    },
    accordion: {
        width: '70%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    headerBack: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        width: '7%',
    },
    arrowIcon: {
        color: theme.colors.textLight,
        fontSize: 28,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
        },
    },
    arrowText: {
        fontSize: 20,
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: theme.fonts.fontFamily,
        color: theme.colors.textLight,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
}));

interface Faq {
    titulo: string;
    resposta: string;
}

const FAQ = () => {

    const history = useHistory();

    const [faqs, setFaqs] = useState<Faq[]>([]);

    useEffect(() => {
        async function readFAQ() {

            try {
                const { data } = await api.get('/readfaq')
                setFaqs(data);
            }
            catch (err) {
                console.log(err);
            }
        }
        readFAQ();
    }, [])

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.headerBackContainer}>
                    <div onClick={() => history.goBack()} className={classes.headerBack}>
                        <ArrowBackIosOutlined className={classes.arrowIcon} />
                        <Typography className={classes.arrowText}>Voltar</Typography>
                    </div>
                </div>
                <Typography variant="h3" align="center" className={classes.title}>
                    FAQ
                </Typography>
                <Typography variant="body1" align="center" className={classes.subtitle}>
                    Você tem alguma dúvida? <br /> Talvez ela já tenha sido respondida!
                </Typography>
            </div>
            <div className={classes.box}>
                {faqs && faqs.map((item: any) => {
                    return (
                        <Accordion className={classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{item.titulo}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className={classes.answer}>
                                    {item.resposta}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>)
                })
                }
                </div>
        </div>
    )
}

export default FAQ;